import Vue from "vue";
import { utils, read } from "xlsx";
class ExcelFile extends Vue {
	constructor(file) {
		super({
			data: {
				file: file,
				fileName: file.name,
				workbook: null,
				sheets: []
			}
		});
	}
	async read() {
		let fileContent = await new Promise(resolve => {
			let reader = new FileReader();
			reader.onload = e => {
				resolve(e.target.result);
			};
			reader.readAsBinaryString(this.file);
		});
		this.workbook = await read(fileContent, { type: "binary" });
		this.sheets = (this.workbook.SheetNames ?? []).map(sheet => {
			return new Sheet(sheet, utils.sheet_to_row_object_array(this.workbook.Sheets[sheet], { header: 1 }));
		});
		return this;
	}
}
class Sheet extends Vue {
	constructor(name, allRows) {
		super({
			data: {
				name: name,
				hasHeader: true,
				allRows: allRows
			},
			computed: {
				header() {
					if (!this.allRows || this.allRows.length == 0) return [];
					return this.allRows[0].map((column, index) => this.hasHeader && column?.toString().trim() ? column?.toString().trim() : String.fromCharCode(65 + index));
				},
				rows() {
					if (!this.allRows) return [];
					return (this.hasHeader ? this.allRows.slice(1) : this.allRows).filter(item => item.join("").trim());
				}
			}
		});
	}
}

export default ExcelFile;
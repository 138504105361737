<template>
    <b-modal id="Campanha-Filtros" 
             centered 
             scrollable
             @hide="fechar"
             hide-header
             size="lg">
        <h5>{{metodo == "campanha" ? "Definir filtros da lista de transmissão" : "Definir filtros de clientes"}}</h5>
        <b-container>           
            <span :id="!disableSelect && camposFiltros.length > 0 ? 'limpaFiltroAtivo' : 'limpaFiltroInativo'" @click="limpaFiltro"><b>Limpar Filtros</b>
            </span>
            <!-- CAMPOS FILTRAVESI =======================-->
            <div  v-for="(campo, i) in camposFiltros" class="filtrosContainer" v-if="!campo.Excluido">
                <b-row align-v="center">
                    <b-col cols="11" class="data-field">
                        <label>Selecione o campo para filtrar:</label>
                        <select v-model="campo.NomeCampo" @change="selectCampoFiltro(i, $event)">
                            <option v-for="(op, j) in selectFiltros" :key="j" :value="op.Id ?? op.NomeCampo" :disabled="opcoesSelecionadas.includes(op.NomeCampo) || opcoesSelecionadas.includes(op.Id)" :style="opcoesSelecionadas.includes(op.NomeCampo) || opcoesSelecionadas.includes(op.Id) ? 'color:red' : 'color:black'">
                            {{ op.NomeCampo }}
                            </option>
                        </select>
                    </b-col>
                    <b-col v-if="campo.NomeCampo" cols="1" style="margin-top: 10px;">
                        <i class="fas fa-trash-alt mr-3 float-right"
                            @click="excluirFiltro(i)" style="cursor:pointer">
                        </i>
                    </b-col>
                </b-row>
                <b-row v-if="campo.NomeCampo && campo.TipoCampo == 0" align-v="center">
                    <b-col cols="12" class="data-field">
                        <label>Conteúdo do filtro:</label>
                        <input type="text" v-model="campo.Resposta">
                    </b-col>
                </b-row>
                <div class="col-12">
                    <b-row v-if="campo.NomeCampo && campo.ClientesComplementoOpcao && campo.TipoCampo == 3" class="opcoesContainer">
                        <b-col class="camposSelect" cols="6" v-for="complementoOpcao in campo.ClientesComplementoOpcao">
                            <input type="checkbox" :id="complementoOpcao.Id" :value="complementoOpcao.Id" v-model="complementoOpcao.isChecked">
                            <label :for="complementoOpcao.Id" class="mx-1">{{ complementoOpcao.NomeOpcao }}</label>
                        </b-col>
                    </b-row>
                </div>
            </div>
            <b-row v-if="!disableSelect" id="div-adicionar-campo">
                <div class="adicionar-opcao-btn">
                    <button class="addButtom"
                        id="add-option-btn"
                        @click="adicionarFiltro">
                    + Adicionar mais um filtro
                    </button>
                </div>
            </b-row>
        </b-container>
        <template #modal-footer="{ cancel }">
            <b-button variant="light" 
                      @click="resetar" 
                      class="rounded-0">
                Cancelar
            </b-button>


            <b-button
                      variant="success" 
                      class="rounded-0"  
                      @click="confirmar"
                      :disabled="!isReady">
                Confirmar
            </b-button>
        </template>
        <ModalLimpezaFiltro ref="Limpa-Filtros" />
    </b-modal>
</template>



<script>
    import axios from 'axios';
    import ModalLimpezaFiltro from '@/components/filtros-cliente/ModalLimpezaFiltro.vue';

    export default {
        name: "ModalFiltrosClientes",
        props: {
        },
        components:{
            ModalLimpezaFiltro
        },
        data() {
            return {
                opcoesSelecionadas: [],
                selectFiltros: [
                    {NomeCampo:"Nome",TipoCampo:0,Resposta:"",Excluido: false, Selected: false},
                    {NomeCampo:"Telefone",TipoCampo:0,Resposta:"",Excluido: false, Selected: false},
                    {NomeCampo:"Email",TipoCampo:0,Resposta:"",Excluido: false, Selected: false},
                    {NomeCampo:"CNPJ/CPF",TipoCampo:0,Resposta:"",Excluido: false, Selected: false},
                    {NomeCampo:"Municipio",TipoCampo:0,Resposta:"",Excluido: false, Selected: false},
                    {NomeCampo:"UF",TipoCampo:0,Resposta:"",Excluido: false, Selected: false}
                ],
                camposFiltros: [
                    {
                        NomeCampo:null,Resposta: "",Excluido: false, Selected: false,TipoCampo: null,
                        ClientesComplementoOpcao: [
                            {isChecked:false}
                        ]
                    }
                ],
                filtrosArmazenados: [],
                camposDisponiveis:[],
                dadosComplementos: [],
                isReady: true,
                enviou: false,
                disableSelect: true,
                metodo: ''
            }
        },
        methods: {
            iniciarModal(metodo,listaFiltros) {
                this.metodo = metodo;
                this.filtrosArmazenados = listaFiltros;
                if(listaFiltros.length > 0 && listaFiltros[0].NomeCampo != null){
                    this.camposFiltros = listaFiltros;
                    this.camposFiltros.forEach(campo => {
                        this.opcoesSelecionadas.push(campo.NomeCampo);
                        this.atualizarOpcoesSelecionadas();
                    })
                    this.disableSelect = false;
                }
                else{
                    this.camposFiltros = [
                        {NomeCampo:null,Resposta: "",Excluido: false,TipoCampo: null,ClientesComplementoOpcao:[], Selected: false}
                    ];
                    this.disableSelect = true;
                }

                this.$bvModal.show("Campanha-Filtros");
                this.carregaComplementos();

                return new Promise(resolve => this.enviou = resolve); 
            },
            carregaComplementos() {
                $("#caixa_carregamento").show();


                if(this.metodo == "campanha"){
                    this.selectFiltros = [
                        {NomeCampo:"Nome",TipoCampo:0,Resposta:"",Excluido: false, Selected: false},
                        {NomeCampo:"Telefone",TipoCampo:0,Resposta:"",Excluido: false, Selected: false},
                        {NomeCampo:"Email",TipoCampo:0,Resposta:"",Excluido: false, Selected: false},
                        {NomeCampo:"CNPJ/CPF",TipoCampo:0,Resposta:"",Excluido: false, Selected: false},
                        {NomeCampo:"Municipio",TipoCampo:0,Resposta:"",Excluido: false, Selected: false},
                        {NomeCampo:"UF",TipoCampo:0,Resposta:"",Excluido: false, Selected: false}
                    ];
                }
                else{
                    this.selectFiltros = []; 
                }
                axios.get("/api/v2/clientes/BuscarConfiguracoes")
                    .then((response) => {
                        response.data.forEach(comp => {
                            this.selectFiltros.push({
                                ClientesComplementoOpcao: comp.ClientesComplementoOpcao, 
                                NomeCampo: comp.NomeCampo,
                                TipoCampo: comp.TipoCampo,
                                ClientesConfigComplementoId: comp.Id,
                                Resposta: null,
                                RespostaOpcaoIds: [],
                                Id: comp.Id,
                                Selected: false
                            });
                        });
                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.error(error);
                        $("#caixa_carregamento").hide()
                    });
            },
            // Função para verificar se um campo existe em um array
            campoExiste(array, campo) {
                return array.some(item =>item.NomeCampo === campo);
            },
            selectCampoFiltro(index,evt){
                this.disableSelect = false;
                let id = evt.target.value;
                let filtroEncontrado =  this.selectFiltros.find(f => f.Id === id) ?? this.selectFiltros.find(f => f.NomeCampo === id);

                if(filtroEncontrado){
                    if(filtroEncontrado.TipoCampo == 3){
                        this.camposFiltros[index].Resposta = '';
                    }
                    this.camposFiltros[index].TipoCampo = filtroEncontrado.TipoCampo;
                    this.camposFiltros[index].ClientesComplementoOpcao = filtroEncontrado.ClientesComplementoOpcao;
                    this.camposFiltros[index].Selected = true;
                    this.selectFiltros.find(f => f.NomeCampo === id) ? this.selectFiltros.find(f => f.NomeCampo === id).Selected = true : this.selectFiltros.find(f => f.Id === id).Selected = true;
                    this.opcoesSelecionadas[index] = id;
                    this.atualizarOpcoesSelecionadas();
                }
            },
            adicionarFiltro(){
                this.disableSelect = true;
                this.camposFiltros.push({NomeCampo:null,Resposta: "",Excluido: false,TipoCampo: null,ClientesComplementoOpcao:[], Selected: false});

            },
            limpaFiltro(){
                this.$refs["Limpa-Filtros"].iniciarModal().then(resolves => {
                    if(resolves == 'limpaVolta'){
                        
                        this.camposFiltros = [{NomeCampo:null,Resposta: "",Excluido: false,TipoCampo: null,ClientesComplementoOpcao:[], Selected: false}];

                        let retornoFiltros = {
                            query : null,
                            campos : null
                        }

                        this.fechar();

                        this.enviou(retornoFiltros);
                    }
                    if(resolves == 'limpa'){
                        this.camposFiltros = [{NomeCampo:null,Resposta: "",Excluido: false,TipoCampo: null,ClientesComplementoOpcao:[], Selected: false}];
                        this.disableSelect = true;
                        this.opcoesSelecionadas = [];
                        this.atualizarOpcoesSelecionadas();
                    }
                });
            },
            excluirFiltro(index){
                this.$bvModal.msgBoxConfirm("Deseja excluir o filtro?", {
                    title: "Atenção",
                    size: "md",
                    buttonSize: "sm",
                    okVariant: "success",
                    okTitle: "Sim",
                    cancelTitle: "Não",
                    centered: true,
                }).then(confirm => {
                    if (confirm) {
                        this.opcoesSelecionadas[index] = null;
                        this.atualizarOpcoesSelecionadas();
                        if(this.camposFiltros.length == 1){
                            this.camposFiltros = [{NomeCampo:null,Resposta: "",Excluido: false,TipoCampo: null,ClientesComplementoOpcao:[], Selected: false}];
                            this.disableSelect = true;
                        }
                        else{
                            this.camposFiltros.splice(index,1);
                        }
                    }
                }); 
            },
            confirmar(){
                let retornoQuery = {};

                this.camposFiltros.map(filtro => {
                    if(!filtro.Excluido){
                        if (filtro.ClientesComplementoOpcao && filtro.ClientesComplementoOpcao.length > 0) {
                            let opcoesSelecionadas = filtro.ClientesComplementoOpcao.filter(opcao => opcao.isChecked);
                            let opcaoIds = opcoesSelecionadas.map(opcao => opcao.Id).join(",");
                            if(opcaoIds){
                                retornoQuery[filtro.NomeCampo] = opcaoIds;
                            }
                        } 
                        else{
                            retornoQuery[filtro.NomeCampo] = filtro.Resposta;
                        }
                    }
                });

                let retornoFiltros = {
                    query : retornoQuery,
                    campos : this.camposFiltros.filter(campo => campo.NomeCampo != null && (campo.Resposta != "" || (campo.ClientesComplementoOpcao && campo.ClientesComplementoOpcao.filter(op => op.isChecked).length > 0)))
                }

                this.fechar();

                this.enviou(retornoFiltros);
            },
            fechar(){
                this.disableSelect = true;
                this.opcoesSelecionadas = [];
                this.dadosComplementos = [];
                this.camposFiltros = [];
                this.selectFiltros =  [];
                this.$bvModal.hide("Campanha-Filtros");
            },
            atualizarOpcoesSelecionadas() {
                this.selectFiltros.forEach(op => {
                    op.Selected = this.opcoesSelecionadas.includes(op.NomeCampo);
                });
            },
            resetar(){

                if(this.camposFiltros.length > 0 && this.camposFiltros[0].NomeCampo != null && JSON.stringify(this.filtrosArmazenados) != JSON.stringify(this.camposFiltros)){
                    this.$bvModal.msgBoxConfirm("Você registrou filtros que serão perdidos, deseja continuar?", {
                        title: "Atenção",
                        size: "md",
                        buttonSize: "sm",
                        okVariant: "success",
                        okTitle: "Sim",
                        cancelTitle: "Não",
                        centered: true,
                    }).then(confirm => {
                        if (confirm) {
                            this.fechar();
                        }
                    }); 
                }
                else{
                    this.fechar();
                }
            }
        }
    }
</script>


<style scoped>
    #div-adicionar-campo{
        justify-content: center;
    }
    #limpaFiltroAtivo{
        cursor: pointer;
        justify-content: end;
        margin-right: 1vw;
        display: grid;
        color: #E74A4A;
        text-decoration: underline;
    }    
    #limpaFiltroInativo{
        pointer-events: none;
        cursor: pointer;
        justify-content: end;
        margin-right: 1vw;
        display: grid;
        color: #737373;
        text-decoration: underline;
    }
    #Campanha-Filtros input[type=text], select {
        opacity: 1;
        background-color: #F5F2F2;
        border: 1px #737373 solid;
        min-width: 100%;
    }

        #Campanha-Filtros input[type=text]:focus {
            background-color: #F5F2F2;
        }
        
    .opcoesContainer{
        display: flex;
        align-items: stretch;
        background-color: #F5F2F2;
        border: solid #000 1px;
        padding-top: 0.6rem;
    }
    .data-field {
        color: #737373;
    } 
    .addButtom {
        padding-left: 40px;
        padding-right: 40px;
        background-color: transparent;
        border: 1px #737373 solid;
        color: #737373;
    }
    #add-option-btn{
        cursor:pointer;
        padding-top:0.7%;
        padding-bottom:0.7%
    }
    .adicionar-opcao-btn {
        margin-top: 2%;
        margin-bottom: 2%;
        text-align: initial;
    }
</style>
<template>
    <b-modal id="Limpa-Filtros" 
             centered 
             scrollable
             hide-header
             size="lg">
        <h5>Atenção</h5>
        <b-container>
            <span>Esta ação limpará <b>todos</b> os filtros. Deseja continuar?</span>
        </b-container>
        <template #modal-footer="{ cancel }">
            <b-button variant="light" 
                      @click="cancela" 
                      class="rounded-0">
                Não
            </b-button>

            <b-button
                      variant="outline-secondary" 
                      class="rounded-0"
                      @click="cancelaVoltaBase">
                Sim, e voltar para a base de clientes
            </b-button>

            <b-button
                      variant="success" 
                      class="rounded-0"
                      @click="cancelaVolta">
                Sim, e voltar para os filtros.
            </b-button>
        </template>
    </b-modal>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "ModalFiltrosCampanha",
        props: {
        },

        data() {
            return {
                selectFiltros: [
                    {NomeCampo:"Nome",TipoCampo:0,Resposta:""},
                    {NomeCampo:"Telefone",TipoCampo:0,Resposta:""},
                    {NomeCampo:"Email",TipoCampo:0,Resposta:""},
                    {NomeCampo:"CNPJ/CPF",TipoCampo:0,Resposta:""},
                    {NomeCampo:"Cidade",TipoCampo:0,Resposta:""},
                    {NomeCampo:"UF",TipoCampo:0,Resposta:""}
                ],
                camposFiltros: [
                    {
                        NomeCampo:null,Resposta: "",TipoCampo: null,
                        ClientesComplementoOpcao: [
                            {isChecked:false}
                        ]
                    }
                ],
                camposDisponiveis:[],
                dadosComplementos: [],
                isReady: true,
                limpou: false,
                disableSelect: true
            }
        },


        computed: {
        },
        watch: {

        },
        methods: {
            iniciarModal() {
                this.$bvModal.show("Limpa-Filtros");
                return new Promise(resolve => this.limpou = resolve); 
            },
            cancela(){
                this.limpou("cancelou");
                this.$bvModal.hide("Limpa-Filtros");
            },
            cancelaVoltaBase(){
                this.limpou("limpaVolta");
                this.$bvModal.hide("Limpa-Filtros");
            },
            cancelaVolta(){
                this.limpou("limpa");
                this.$bvModal.hide("Limpa-Filtros");
            }

        }
    }
</script>

<style scoped>
</style>